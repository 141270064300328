import { LOCATION_CHANGE } from 'react-router-redux';
import { REACT_ROUTER_MATCH } from '@dbh/redux-route-internals';
import { select, call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { isServerSideRendering } from '@dbh/environment';
import { forkSagaChannelAndPutEvent, handleSagaError, takeOnce } from '@dbh/redux-saga-extra';
import { SSR_STARTED } from '@dbh/ssr-data-redux';
import { makeSelectWebpagesLoaded, makeSelectWebpages, makeSelectCurrentWebpageApiUrl, temporaryAddHtmlTagToWebpagesApiResponse, handleCheckRoutingIssuesOnLoadWebpagesSuccess, makeSelectWebpagesRoutesLoaded, makeSelectWebpagesRoutes, makeSelectUrlApiWebpagesRoutes, validateWebpagesRoutes, handleNavigateToHomePage, handleLocationChangedToWebpagesRoute, handleBrowserRedirectOnLoadWebpagesSuccess, handleBrowserRedirectOnLandingPage } from '@dbh/webpages-redux';
import { webpagesLoaded, webpagesLoadingError, webpagesRoutesLoaded, webpagesRoutesError, LOAD_WEBPAGES_ROUTES_INVOKED, NAVIGATED_TO_HOME_PAGE, LOAD_WEBPAGES_INVOKED, NAVIGATED_TO_WEBPAGES_ROUTE, LOAD_WEBPAGES_SUCCESS, REDUX_AND_SAGA_KEY } from '@dbh/webpages-events-and-constants-redux';
import { DAEMON } from '@dbh/redux-extra';
import { fromJS, Map } from 'immutable';
import 'invariant';
import { channel } from 'redux-saga';
import { FE_API_IDS, API_IDS } from '@dbh/api-constants';
import { makeSelectFrontEndApiUrl } from '@dbh/configuration-redux';
import { request } from '@dbh/request-queue-redux';
import { makeSelectLocationPathname, makeSelectLocationSearch, makeSelectLocale, makeSelectCountry } from '@dbh/routing-redux';
import { makeSelectSagaRequestOptions } from '@dbh/saga-request-options';
import '@dbh/urls';
import { selectUserAreaData, makeSelectUserLoaded } from '@dbh/user-area-redux';
import { channelWatcher, apiDataValidatorChannelWatcher } from '@dbh/webcontrols-redux';
import { handleLoadNotFoundPageWebcontrolsIfRequired } from '@dbh/not-found-page-webcontrols';
import { loadHotelsApiDataSSR } from '@dbh/hotels-redux';
import { userAreaUserBookingsChannelWatcher } from '@dbh/user-area-bookings-redux';
import { ApiRequestError } from '@dbh/request-errors';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

function*handleLoadWebpagesSearchDate(){const a=yield select(makeSelectLocationPathname()),b=yield select(makeSelectLocationSearch()),[c]=yield select(makeSelectFrontEndApiUrl(FE_API_IDS.searchDate,{queryParameters:{url:""+a+(b||"")}})),d=yield select(makeSelectSagaRequestOptions(FE_API_IDS.searchDate)),e=yield call(request,c,d),{apiResponse:{searchDate:g},emptyResponse:f}=e;if(f)throw new ApiRequestError(e);return g}

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResult} SagaEventResult
 */ /**
 * In server side rendering, we would need to set up a `redux-saga` channel to
 * send the "webpages loaded" event to the
 * `handleCheckRoutingIssuesOnLoadWebpagesSuccess` saga. To make it easier here,
 * we just call it. The saga must run in server side rendering, to print a red
 * screen in case of routing issues.
 * @param {Object} webpagesLoadedEvent .
 * @yields {SagaEventResult?} .
 */function*callCheckRoutingIssuesSagaInServerSideRendering(a){isServerSideRendering()&&(yield call(handleCheckRoutingIssuesOnLoadWebpagesSuccess,a));}/**
 * Loads the `webpages` ("Web Pages" in the `admindev`) for the current
 * or optionally provided combination of country and locale. Even if the
 * requested data is already in the `redux` store, it sends the success event
 * and sets `fromCache: true`. This allows other sagas to always expect either
 * a success or failure event, when this saga ends.
 * @param {Object} event .
 * @yields {SagaEventResult?} .
 */function*handleLoadWebpagesInvoked(a){const{locale:b,country:c,forceFetch:d}=a,e=yield b||select(makeSelectLocale()),f=yield c||select(makeSelectCountry()),g=yield select(makeSelectLocationPathname());if(!d){// Cache.
const a=yield select(makeSelectWebpagesLoaded());if(a){const a=yield select(makeSelectWebpages()),b=yield select(selectUserAreaData),c=yield select(makeSelectUserLoaded()),d=a.get("templateCode"),h=webpagesLoaded({webpages:a,locale:e,country:f,pathname:g,templateCode:d,userAreaData:b,isUserLoaded:c,fromCache:!0});return yield put(h),void(yield call(callCheckRoutingIssuesSagaInServerSideRendering,h))}}const h=yield select(makeSelectCurrentWebpageApiUrl());const i=yield select(makeSelectSagaRequestOptions(API_IDS.webpages));try{const[b,c]=yield all([call(request,h,i),call(handleLoadWebpagesSearchDate)]),{emptyResponse:d,apiResponse:j,requestAttributes:k}=b;if(d)throw new ApiRequestError(b);const l=temporaryAddHtmlTagToWebpagesApiResponse(j["hydra:member"][0]),{templateCode:m}=l,n=yield select(selectUserAreaData),o=yield select(makeSelectUserLoaded());let p=l;// @TODO: when this code is in production, open a back end card to make
// them remove the `searchDate` field inside the `searchContext` of the
// `/webpages` `API`.
const q=p.searchContext||{};p.searchContext={...q,searchDate:c};const r=webpagesLoaded({webpages:fromJS(p),locale:e,country:f,requestAttributes:k,pathname:g,templateCode:m,userAreaData:n,isUserLoaded:o});// We do not need nor want to use `redux-saga` channels or to directly call
// sagas in the Front End, so we check if we are in `SSR`.
if(yield put(r),yield call(callCheckRoutingIssuesSagaInServerSideRendering,r),isServerSideRendering()){yield call(loadHotelsApiDataSSR,{templateCode:m});// Create channel to communicate with the `webcontrols` saga in `SSR`.
const a=yield call(channel);yield fork(channelWatcher,a),yield put(a,r),a.close();}yield call(handleLoadNotFoundPageWebcontrolsIfRequired,a),yield forkSagaChannelAndPutEvent(apiDataValidatorChannelWatcher,r),yield forkSagaChannelAndPutEvent(userAreaUserBookingsChannelWatcher,r);}catch(a){// We throw and let `serverEntry` handle the rendering of an error message,
// since this is a particularly "bad" failure and we can't recover and
// render our front end.
throw handleSagaError(a),"Invariant Violation"!==a.name&&(yield put(webpagesLoadingError({country:f,locale:e,error:a,pathname:g}))),a}}

/**
 * @typedef {import('@dbh/redux-saga-extra').SagaEventResult} SagaEventResult
 */ /**
 * Loads the `webpagesRoutes` ("Web Pages" in the `admindev`) for the current
 * or optionally provided combination of country and locale. Even if the
 * requested data is already in the `redux` store, it sends the success event
 * and sets `fromCache: true`. This allows other sagas to always expect either
 * a success or failure event, when this saga ends.
 * @param {Object} event .
 * @yields {SagaEventResult?} .
 */function*handleLoadWebpagesRoutesInvoked(a){let b,c,d,e;const{type:f}=a;switch(f){case SSR_STARTED:{b=!0;break}case LOCATION_CHANGE:{const{payload:{countryOrLocaleChanged:e,locale:f,country:g}}=a;b=e,c=f,d=g;break}case LOAD_WEBPAGES_ROUTES_INVOKED:{const{payload:{country:b,locale:f,forceFetch:g}}=a;c=f,d=b,e=g;break}}c&&d||(c=yield select(makeSelectLocale()),d=yield select(makeSelectCountry()));const g=Map({country:d,locale:c});if(!e){// Cache.
const a=yield select(makeSelectWebpagesRoutesLoaded(g)),e=a||f===LOCATION_CHANGE&&!b;if(e){const a=yield select(makeSelectWebpagesRoutes(g));return void(yield put(webpagesRoutesLoaded({webpagesRoutes:a,country:d,locale:c,fromCache:!0})))}}// The data is not cached, we request it from the `API`.
const h=yield select(makeSelectUrlApiWebpagesRoutes({country:d,locale:c})),i=yield select(makeSelectSagaRequestOptions(API_IDS.webpages));try{const a=yield call(request,h,i),{emptyResponse:b,apiResponse:e,requestAttributes:f}=a;if(b)throw new ApiRequestError(a);const g=e["hydra:member"];validateWebpagesRoutes(g,h);const j=webpagesRoutesLoaded({requestAttributes:f,webpagesRoutes:g,country:d,locale:c});yield put(j),yield forkSagaChannelAndPutEvent(channelWatcher,j);}catch(a){// We throw and let `serverEntry` handle the rendering of an error message,
// since this is a particularly "bad" failure and we can't recover and
// render our front end.
throw handleSagaError(a),yield put(webpagesRoutesError({error:a,country:d,locale:c})),a}}

/**
 * Handles the load of the  `webpages` and `webpages` routes `APIs` on location
 * change.
 * @param {Object} event .
 */const handleLoadWebpagesOnLocationChange=withConformsTo("handleLoadWebpagesOnLocationChange",[],function*(a){yield all([call(handleLoadWebpagesInvoked,a),call(handleLoadWebpagesRoutesInvoked,a)]);});var handleLoadWebpagesOnLocationChange$1 = handleLoadWebpagesOnLocationChange;

function*webpagesRootSaga(){yield all([takeLatest(NAVIGATED_TO_HOME_PAGE,handleNavigateToHomePage),takeLatest(LOAD_WEBPAGES_INVOKED,handleLoadWebpagesInvoked),takeLatest(LOCATION_CHANGE,handleLoadWebpagesOnLocationChange$1),takeLatest(LOAD_WEBPAGES_ROUTES_INVOKED,handleLoadWebpagesRoutesInvoked),takeLatest(NAVIGATED_TO_WEBPAGES_ROUTE,handleLocationChangedToWebpagesRoute),takeLatest(LOAD_WEBPAGES_SUCCESS,handleCheckRoutingIssuesOnLoadWebpagesSuccess),// Note: `LOCATION_CHANGE` is fired before `SSR_STARTED` in SSR, but the
// SAGAs don't pick up any event before `SSR_STARTED`. Also, we use `takeLatest`
// since `take` doesn't work here, even though it wouldn't be needed because
// there won't (correctly) be a second `SSR_STARTED` event.
takeLatest(SSR_STARTED,handleLoadWebpagesInvoked),takeLatest(SSR_STARTED,handleLoadWebpagesRoutesInvoked),!isServerSideRendering()&&takeLatest(LOAD_WEBPAGES_SUCCESS,handleBrowserRedirectOnLoadWebpagesSuccess),!isServerSideRendering()&&takeOnce(REACT_ROUTER_MATCH,handleBrowserRedirectOnLandingPage)].filter(Boolean));}const injectedSagaConfiguration={key:REDUX_AND_SAGA_KEY,mode:DAEMON};

export { webpagesRootSaga as default, injectedSagaConfiguration };
